<template>
	<div class="team">
		<!-- header -->
		<homePage :urlFlag="5"></homePage>
        <!-- banner -->
        <div class="container-fluid bannerBox bannerImg">
            <img src="@/assets/img_banner_team.png">
            <div>
                <div class="container">
                    <h2>SRL LEADERSHIP</h2> 
                    <p>丝路里梁柱，发展之源</p>
                </div>
            </div>
        </div>
        <!-- 管理团队 -->
        <div class="container newsList">
            <h2 class="bigTietle"> 
				<span>
					<img src="@/assets/smallLogo.png" alt="" class="smallLogo">
					管理团队
				</span>
			</h2>
            <p class="news">NEWS CENTER</p>
            <img src="@/assets/img_team_1.png" class="teamImg">
            <p class="product">
                2017年在成都创立丝路供应，平均每年以60%以上的增长速度，4年时间将丝路供应建设成拥有成都青白江、四川南充、重庆、西安13家分公司，100人在职员工的规模，行业口碑遥遥领先。<br/>
                丝路供应“竞争，成长，共创，共享”的企业文化，秉承服务客户，创造价值的理念，将员工，企业，客户，社会的价值链串联起来。创新服务模式，打造出“物流+信息技术+金融”的全新模式，为经济社会的发展提供便捷高效的供应链服务。
            </p>
            <div class="row productBox">
                <div class="col-md-2 productName" ref="produtWidth">
                    <div>货代</div>
                    <p v-if="false">2019-10</p>
                </div>
                <div class="col-md-5 col-sm-6 productDetail">
                    <div>客户货运方案定制</div>
                    <p>公司货代由销售团队和操作团工共同组成，销售团队是一个富有活力的团队，热爱市场营销工作，积极开拓市场，有责任心、有条理、有亲和力，有较强的学习能力和沟通能力、自律性极强。操作团队具有多年的工作经验，稳重、专业却不缺乏创新意识，勇于挑战，不断优化流程加强部门之间的对接与沟通，团队之间相互协作，快速为顾客定制最优方案。</p>
                    <img src="@/assets/img_team_2.png" alt="">
                </div>
                <div class="col-md-5 col-sm-6 productImg">
                    <img src="@/assets/img_team_2.png" alt="" style="height: 200px; width: 356px">
                </div>
                <div class="crossing" :style="{left:crossingLeft}"></div>
            </div>
            <div class="row productBox">
                <div class="col-md-2 productName" ref="produtWidth">
                    <div>报关</div>
                    <p v-if="false">2019-10</p>
                </div>
                <div class="col-md-5 col-sm-6 productDetail">
                    <img src="@/assets/img_team_3.png" alt="">
                    <div>进出口货物资料送审</div>
                    <p>公司建立了研发中心，专注于互连方案的设计和制造，本中心的技术和管理团队核心人员，均有数十年以上的行业从业经验，有着丰富的理论和实践经验。凝聚和内部培养了在连接器设计与制造领域拥有丰富经验的专业团队，目前，在基础研究、设计应用、工艺制造、测试等各个方面的专业队伍共计78人。</p>
                </div>
                <div class="col-md-5 col-sm-6 productImg">
                    <img src="@/assets/img_team_3.png" alt="" style="height: 200px; width: 356px">
                </div>
                <div class="crossing" :style="{left:crossingLeft}"></div>
            </div>
            <div class="row productBox">
                <div class="col-md-2 productName" ref="produtWidth">
                    <div>仓储</div>
                    <p v-if="false">2019-10</p>
                </div>
                <div class="col-md-5 col-sm-6 productDetail">
                    <img src="@/assets/img_team_4.png" alt="">
                    <div>货物装箱、拆分、打包</div>
                    <p>四川丝路里与台湾邦联集团旗下合资的专业保税仓运营管理公司。 引入邦联集团10多年的保税仓运营经验， 全国50多个转运中心。 为各类型客户提供最专业，最完善的物流供应链服务。</p>
                </div>
                <div class="col-md-5 col-sm-6 productImg">
                    <img src="@/assets/img_team_4.png" alt="" style="height: 200px; width: 356px">
                </div>
                <div class="crossing" :style="{left:crossingLeft}"></div>
            </div>
            <div class="row productBox">
                <div class="col-md-2 productName" ref="produtWidth">
                    <div>跨境</div>
                    <p v-if="false">2019-10</p>
                </div>
                <div class="col-md-5 col-sm-6 productDetail">
                    <img src="@/assets/img_team_5.png" alt="">
                    <div>跨境货物代理</div>
                    <p>一支实战经验丰富、素质较高的专业团队，其负责人涉足跨境电商时间早经验丰富。作为成都较早进入的跨境电商企业，见证了跨境电商的快速发展，积累了丰富的快消品跨境电商操作经验，团队成员年轻而且充满朝气，具有创新意识并勇于迎接挑战。</p>
                </div>
                <div class="col-md-5 col-sm-6 productImg">
                    <img src="@/assets/img_team_5.png" alt="" style="height: 200px; width: 356px">
                </div>
                <div class="crossing" :style="{left:crossingLeft}"></div>
            </div>
        </div>
		<afterbody></afterbody>
	</div>
</template>

<script>
// @ is an alias to /src
import homePage from "@/components/homePage/homePage.vue";
import afterbody from "@/components/afterbody/afterbody.vue";

export default {
	name: "team",
	components: {
		homePage,
		afterbody
	},
	data() {
		return {
            crossingLeft: 0
		}
	},
	methods: {
		jumpUrl() {
			this.$router.push('/newsDetail')
		}
	},
	mounted() {
        this.$nextTick(() => {
            this.crossingLeft = this.$refs.produtWidth.offsetWidth + 'px'
        })
	},
};
</script>

<style scoped>
@import "team.css";
</style>
